import React from "react"
import Layout from "../layout/layout"
import {Link} from "gatsby"

const AboutPage = () => (
  <Layout>
    {/* <SEO title="Page two" /> */}
    <header className="post-header">
      <h1 className="post-title">About</h1>
    </header>
    <article className="post-content">
      <p>My name is Ameth Cruz (@4me7h).</p>

      <p>I'm passionate about technology, design, and business, and how they interact with each other and humans.</p>

      <p>I spend my time on the computer creating programs and designing for the World Wide Web, learning new and interesting stuff, meeting creative and cool people, following my fondness for the Internet and technology.</p>

      <p>Since 2008 I've written on blogs about technology and geek culture. At the same time, I've developed <Link to="/web">web projects</Link> for various local and international brands.</p>

      <p>Sometimes I like to make some <Link to="/music">music</Link>. I play guitar and experiment with computer programs, nothing serious, I just love playing music. </p>
    </article>
  </Layout>
)

export default AboutPage
